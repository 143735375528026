import { PropsWithChildren, ReactElement, createContext, useContext, useMemo } from 'react';
import { WaitingConversationInfo } from '../../models/reports.queues';
import { QueueReportsContext } from './QueueReportsProvider';
import usePersistTaskStates, { WorkItemPickErrorState } from './WaitingConversationsProvider.usePersistTaskStates';

interface WaitingConversationsContextValue {
  takeableWaitingConversations: WaitingConversationInfo[];
  allWaitingConversations: WaitingConversationInfo[];
  setConversationErrorState(conversationId: WaitingConversationInfo['conversationId'], errorState: WorkItemPickErrorState): void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const WaitingConversationsContext = createContext<WaitingConversationsContextValue>(undefined!);

/**
 * Provides the waiting conversations by getting them from the queues and filtering them based on previously discovered errors (like not being skilled enough to pick it).
 *
 * **Provider dependencies:**
 * - QueueReportsProvider
 */
export default function WaitingConversationsProvider({ children }: PropsWithChildren): ReactElement {
  const queues = useContext(QueueReportsContext);

  const { errorStates, setConversationErrorState } = usePersistTaskStates();
  
  const allWaitingConversations = useMemo<WaitingConversationInfo[]>(
    () => queues
      .flatMap(q => q.conversationsWaiting.map<WaitingConversationInfo>(c => ({ ...c, queue: { id: q.id, name: q.name, mediaGroup: q.mediaGroup } }))),
    [queues],
  );

  const takeableWaitingConversations = useMemo(
    () => allWaitingConversations
      .filter(c => c.routingMode !== 'Automatic')
      .filter(c => errorStates[c.conversationId] === undefined),
    [errorStates, allWaitingConversations],
  );

  const value = useMemo(
    () => ({
      takeableWaitingConversations,
      allWaitingConversations,
      setConversationErrorState,
    }),
    [takeableWaitingConversations, allWaitingConversations, setConversationErrorState],
  );

  return (
    <WaitingConversationsContext.Provider value={value}>
      {children}
    </WaitingConversationsContext.Provider>
  );
}